<template>
  <div class="footer-widget mb-0">
    <div class="row footer-widget__content">
      <div v-for="(item,index) in contactWidgetData" :key="index" class="col-md col-12 coontact-info">
        <div class="contact-title">
          <div class="contact-title--en">
            {{ item.titleEN }}
          </div>
          <div class="contact-title--cn">
            {{ item.titleCN }}
          </div>
        </div>
        <div class="contact-desc">
          {{ item.address + "\n电话/Tel: " + item.phone }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['contactWidgetData']
}
</script>

<style lang="scss" scoped>
  .footer-widget__title-empty{
    height: 0;
    margin: 0;
    padding: 0;
  }

  .footer-widget__content{
    justify-content: space-between;
    :first-child{
      margin-top: 0;
    }
  }

  .contact-info{
    @media screen and (max-width: 992px) {
      margin-top: 10px;
    }
  }

  .contact-title{
    display: flex;
    flex-direction: row;
    background-color: #B49774;
    padding: 2px;
    border-radius: 2px;
    width: fit-content;

    &--en{
      padding: 0 4px;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
    }

    &--cn{
      border-radius: 0 2px 2px 0;
      padding: 0 4px;
      color: #B49774;
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
    }
  }

  .contact-desc{
    margin-top: 10px;
    white-space: pre-line;
  }
</style>
