<template>
  <!--====================  footer area ====================-->
  <div class="footer-area dark-bg">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="footer-content-wrapper section-space--inner--50">
            <div class="row">
              <div class="col-lg-4 col-12">
                <FooterAbout :logo="$d.logoUrlList" />
              </div>
              <div class="col-lg-8 col-12">
                <ContactWidget :contact-widget-data="$d.contactInfoList" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-copyright-wrapper">
      <div class="footer-copyright text-center">
        Copyright © 2022. All right reserved
      </div>
      <div class="footer-copyright text-center">
        <a href="https://beian.miit.gov.cn">浙ICP备2021022690号-1</a>
      </div>
    </div>
  </div>
  <!--====================  End of footer area  ====================-->
</template>

<script>
import FooterAbout from '@/components/FooterAbout.vue'
import ContactWidget from '@/components/ContactWidget.vue'

export default {
  components: {
    FooterAbout,
    ContactWidget
  },
  data() {
    return {
    }
  }
}
</script>

<style lang="scss">

</style>
