<template>
  <div class="footer-intro-wrapper">
    <div class="row logo-area">
      <div class="col-lg-12 col logo-container">
        <img :src="logo.en" class="img-fluid" alt="logo">
      </div>
      <div class="col-lg-12 col logo-container">
        <img :src="logo.cn" class="img-fluid" alt="logo">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['logo']
}
</script>

<style lang="scss" scoped>
.logo-area{
  :first-child{
    margin-top: 0;
  }
  justify-content: space-between;
}

.logo-container{
  overflow: hidden;
  @media screen and (min-width: 992px) {
    margin-top: 10px;
  }
}

.img-fluid{
  max-height: 52px;
  object-fit: contain;
  transform: translateY(-60px);
  filter: drop-shadow(#B49774 0 60px);
}
</style>
