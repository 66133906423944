<template>
  <!--====================  header area ====================-->
  <div class="header-area">
    <div class="header-area__desktop header-area__desktop--default">

      <!--=======  header navigation area  =======-->
      <fixed-header>
        <div class="header-navigation-area default-bg">
          <div class="container">
            <!-- mobile menu -->
            <div id="mobile-menu-trigger" class="mobile-navigation-icon" @click="mobiletoggleClass('addClass', 'active')">
              <i />
            </div>
            <div class="logo">
              <router-link to="/">
                <img :src="$d.logoUrlList.cn" class="img-fluid">
              </router-link>
            </div>

            <div class="row">
              <div class="col-lg-12">
                <!-- header navigation -->
                <div class="header-navigation header-navigation--header-default position-relative">
                  <div class="header-navigation__nav position-static">
                    <nav>
                      <ul>
                        <li>
                          <router-link to="/">主页</router-link>
                        </li>
                        <li>
                          <router-link to="/about">关于我们</router-link>
                        </li>
                        <li>
                          <router-link to="/service">业务范围</router-link>
                        </li>
                        <li>
                          <router-link to="/project">商业案例</router-link>
                        </li>
                        <li>
                          <router-link to="/blog">资讯</router-link>
                        </li>
                        <li>
                          <router-link to="/contact">联系我们</router-link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </fixed-header>
      <!--=======  End of header navigation area =======-->
    </div>
  </div>
  <!--====================  End of header area  ====================-->
</template>

<script>
import FixedHeader from 'vue-fixed-header'
export default {
  components: {
    FixedHeader
  },
  methods: {
    // offcanvas mobilemenu
    mobiletoggleClass(addRemoveClass, className) {
      const el = document.querySelector('#offcanvas-menu')
      if (addRemoveClass === 'addClass') {
        el.classList.add(className)
      } else {
        el.classList.remove(className)
      }
    }
  }
}
</script>

<style lang="scss">
    .vue-fixed-header--isFixed {
        left: 0;
        top: 0;
        width: 100vw;
        z-index: 999;
        position: fixed;
        box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
        animation: 900ms cubic-bezier(0.2, 1, 0.22, 1) 0s normal none 1 running fadeInDown;
    }
</style>
